export enum FlagName {
  EXAMPLE = "example",
  CONFIGURATOR_SNAPSHOTS = "configurator-snapshots",
  CONFIGURATOR_SNAPSHOTS_INTERVAL = "configurator-snapshots-interval",
  CONFIGURATOR_SNAPSHOTS_QUALITY = "configurator-snapshots-quality",
  STUDIO_THUMBNAIL_GENERATION = "studio-thumbnail-creation",
  STUDIO_QUOTE_ARTWORK_ZIP = "studio-quote-artwork-zip",
  OFFLINE_THUMBNAIL_GENERATION = "offline-thumbnail-generation",
  ACCOUNT_ORDER_AGAIN = "account-order-again",
  STICKERS_IN_STUDIO = "stickers-in-studio",
  FLOOD_FILL_FOR_DIGITAL_PRINT = "digital-print-flood-fill",
  TEMPLATES_IN_STUDIO = "templates-in-studio",
  SAVE_TEMPLATE_BUTTON = "templates-save-as-template-button",
  SKU_CREATOR_ALLOW_COMBINED_ARTWORK = "sku-creator-allow-combined-artwork",
  STUDIO_TEXT_JUSTIFICATION_OPTIONS_IN_CONTEXT_MENU = "studio-text-justification-options-in-context-menu",
  REQUIRE_COMPANY_NAME_IN_SIGNUP_FLOW = "require-company-name-in-signup-flow",
  STUDIO_COLOUR_PICKER_DISABLE_HEX_AND_RGB = "studio-colour-picker-disable-hex-and-rgb",
  SKU_MISC_FILE_UPLOAD = "sku-misc-file-upload",
  ARTWORK_CHECK_MULTI_ASSIGN = "artwork-check-multi-assign",
  ALLOW_CONFIGURATION_DRAFT_EDITING = "allow-configuration-draft-editing",
  UNBLOCK_ORDERS_ENABLED = "unblock-orders-enabled",
  SPRING_DEBUG_ENABLED = "spring-debug-enabled",
}
